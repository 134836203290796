// extracted by mini-css-extract-plugin
export var animatedText = "Pack-module--animatedText--eca85";
export var content = "Pack-module--content--25072";
export var description = "Pack-module--description--f5127";
export var image = "Pack-module--image--50999";
export var info = "Pack-module--info--7a479";
export var link = "Pack-module--link--1b3ad";
export var linkText = "Pack-module--linkText--7225c";
export var linkWrapper = "Pack-module--linkWrapper--4c9d7";
export var results = "Pack-module--results--dc06f";
export var root = "Pack-module--root--e83fb";
export var title = "Pack-module--title--85d8d";