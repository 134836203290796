import React, { FC, useState } from 'react';
import classNames from 'classnames';
// components
import Typography from 'src/components/Typography';
// services
import { leadingZero } from 'src/services/helpers';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';

import * as style from './DiscoveryPhaseList.module.scss';
import type {
  DiscoveryPhaseListProps,
  ItemProps,
} from './DiscoveryPhaseList.interface';

const minStepToColumn = 4;
const defaultLastIndex = 3;

const Item: FC<ItemProps> = ({ index, step }) => (
  <div className={style.step}>
    <Typography className={style.stepNumber} variant='body'>
      {leadingZero(index + 1)}
    </Typography>
    <div className={style.stepDescription}>
      <Typography className={style.stepTitle} variant='h4'>
        {step.title}
      </Typography>
      <ul
        className={classNames(style.stepListWrapper, {
          [style.stepListColumns]: step.features.length > minStepToColumn,
        })}
      >
        {step.features.map((item, itemIndex) => (
          <li key={itemIndex} className={style.stepList}>
            <div className={style.dot} />
            <Typography className={style.stepListItem} variant='h5'>
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const DiscoveryPhaseList: FC<DiscoveryPhaseListProps> = ({ steps }) => {
  // hooks
  const { isMobile } = useWindowSize();
  // state
  const [lastIndex, setLastIndex] = useState<number>(defaultLastIndex);

  const handleShowMore = (index: number) => {
    setLastIndex(index);
  };

  return (
    <div className={style.steps}>
      {steps.map((step, index) => {
        if (isMobile)
          return (
            <React.Fragment key={index}>
              {index < lastIndex && <Item index={index} step={step} />}
            </React.Fragment>
          );

        return <Item key={index} index={index} step={step} />;
      })}
      {isMobile && steps.length > 1 && lastIndex !== steps.length && (
        <div onClick={() => handleShowMore(steps.length)}>
          <Typography className={style.text} variant='h5'>
            Read more
          </Typography>
        </div>
      )}
    </div>
  );
};

export default DiscoveryPhaseList;
