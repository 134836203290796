import { FC, useEffect } from 'react';
import classNames from 'classnames';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import Link from 'src/components/Link';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showFromOpacity } from 'src/animations/block';

import * as style from './Pack.module.scss';

type Props = {
  title: string;
  description: string;
  team: string[];
  results: string[];
  pricing: string;
  image: IGatsbyImageData;
};

const Pack: FC<Props> = ({
  title,
  image,
  team,
  description,
  pricing,
  results,
}) => {
  useEffect(() => {
    const animation = showFromOpacity(`.${style.linkWrapper}`, {
      duration: 2,
    }).pause();

    onHTMLElementEnterOnWindow(`.${style.linkWrapper}`, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section className={style.root} title={title}>
      <div className={style.content}>
        <Typography
          className={classNames(style.description, style.animatedText)}
          variant='body'
        >
          {description}
        </Typography>
        <div className={style.info}>
          <div>
            <Typography
              className={classNames(style.title, style.animatedText)}
              variant='h3'
            >
              Your team
            </Typography>
            {team.map((member, index) => (
              <Typography
                className={style.animatedText}
                key={index}
                variant='body'
              >
                {member}
              </Typography>
            ))}
          </div>
          <div className={style.results}>
            <Typography
              className={classNames(style.title, style.animatedText)}
              variant='h3'
            >
              Results
            </Typography>
            {results.map((result, index) => (
              <Typography
                className={style.animatedText}
                key={index}
                variant='body'
              >
                {result}
              </Typography>
            ))}
          </div>
          <Typography className={style.animatedText} variant='h3'>
            {pricing}
          </Typography>
          <div className={style.linkWrapper}>
            <Link
              className={style.link}
              textClassName={style.linkText}
              text='Get'
              iconRightName='arrowRight'
              href='#'
            />
          </div>
        </div>
      </div>
      <div className={style.image}>
        <GatsbyImage
          alt={title}
          image={image.childImageSharp.gatsbyImageData}
        />
      </div>
    </Section>
  );
};

export default Pack;
