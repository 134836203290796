import { useEffect } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import DiscoveryPhaseList from 'src/components/DiscoveryPhaseList';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import * as style from './Included.module.scss';
import useIncludesData from 'src/cms/data/discoveryPhase/useIncludesData';

const Included = () => {
  // hooks
  const { title, steps } = useIncludesData();

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`, {
      duration: 0.5,
    }).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section title={title}>
      <div className={style.content}>
        <Typography
          className={classNames(style.text, style.animatedText)}
          variant='body'
        >
          Description
        </Typography>
      </div>
      <DiscoveryPhaseList steps={steps} />
    </Section>
  );
};

export default Included;
