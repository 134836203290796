// extracted by mini-css-extract-plugin
export var dot = "DiscoveryPhaseList-module--dot--a1aeb";
export var step = "DiscoveryPhaseList-module--step--03877";
export var stepDescription = "DiscoveryPhaseList-module--stepDescription--a8cba";
export var stepList = "DiscoveryPhaseList-module--stepList--e5b97";
export var stepListColumns = "DiscoveryPhaseList-module--stepListColumns--89333";
export var stepListItem = "DiscoveryPhaseList-module--stepListItem--75d78";
export var stepListWrapper = "DiscoveryPhaseList-module--stepListWrapper--e2459";
export var stepNumber = "DiscoveryPhaseList-module--stepNumber--28241";
export var stepTitle = "DiscoveryPhaseList-module--stepTitle--fba59";
export var steps = "DiscoveryPhaseList-module--steps--ad98f";
export var text = "DiscoveryPhaseList-module--text--7bb0b";